import React from "react";
import ContactForm from "../components/ContactForm";

function Contact() {
  return (
    <>
      <h1>Contact Me</h1>
      <ContactForm/>
    </>
  );
}

export default Contact;
